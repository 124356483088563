import React, { useState } from 'react';
import { Link, useStaticQuery } from "gatsby"
import logo from '../assets/images/logo.jpg'

const Header = () => {

    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className="section header clearfix">

            <div className="header-main">
                <div className="innerwrap">
                    <div className="row">
                        <div className="grid-2">
                            <div className="logo">
                                <Link to="/">
                                    <img src={logo} />
                                </Link>
                            </div>
                        </div>
                        <div className="grid-10">
                            <div className={["header-menu", showMenu ? "active" : ""].join(" ")}>
                                <span className="toggleMenu" onClick={(e) => {
                                    e.preventDefault();
                                    setShowMenu(!showMenu);
                                    !showMenu ? document.body.classList.add('menuopen') : document.body.classList.remove('menuopen');
                                }}>
                                    {!showMenu ? <ion-icon name="menu"></ion-icon> : <ion-icon name="close"></ion-icon>}
                                </span>
                                <ul className="menu" onClick={() => {
                                    document.body.classList.remove('menuopen');
                                    setShowMenu(false);
                                }}>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link>About Us <ion-icon name="caret-down"></ion-icon></Link>
                                        <ul className="submenu">
                                            <li><Link to="/about-us">About SavNƏT</Link></li>
                                            <li><Link to="/about-us#director-message">Director Message</Link></li>
                                            <li><Link to="/about-us#why-us">Why SavNƏT Coaching</Link></li>
                                            {/* <li><Link to="/about-us#faculty">Our Faculty</Link></li> */}
                                        </ul>
                                    </li>
                                    <li><Link to="/courses">Courses</Link></li>
                                    {/* <li><Link to="#">Admission <ion-icon name="caret-down"></ion-icon></Link>
                                            <ul className="submenu">
                                                <li><Link to="/">NRE (CET)</Link></li>
                                                <li><Link to="/">Master Course</Link></li>
                                                <li><Link to="/">Main for Bank & SSC</Link></li>
                                                <li><Link to="/">Interview</Link></li>
                                            </ul>
                                        </li> */}
                                    {/* <li><Link to="#">Academics <ion-icon name="caret-down"></ion-icon></Link>
                                            <ul className="submenu">
                                                <li><Link to="/">Online Classes</Link></li>
                                                <li><Link to="/">Online Test Series</Link></li>
                                                <li><Link to="/">Apply Online</Link></li>
                                                <li><Link to="/">Study Tour</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/gallery">Gallery</Link></li>
                                        <li><Link to="/career">Career</Link></li> */}
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Header;