import { Link } from 'gatsby';
import React from 'react';

const FooterCreds = () => {
    return(
        <div className="section footercreds">
            <div className="innerwrap">
                <div className="row">
                    <div className="grid-6 grid-sm-12 grid-xs-12">
                        <p>©2021 by SavNƏT</p>
                    </div>
                    <div className="grid-6 grid-sm-12 grid-xs-12">
                        <p className="last">Developed and Maintained by Vitharme Design's</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Footer = () => {
    return(
        <>
        <div className="section footer">
            <div className="innerwrap">
                <div className="row">
                    <div className="grid-5 grid-sm-12 grid-xs-12">
                        <div className="footer-col">
                            <div className="logo">
                                <h2>SavNƏT</h2>
                            </div>
                            <div className="content">
                                <p>SavNƏT is an Australian Company founded by an experienced IT Professional from india, who migrated to Australia after spending his time in India and is in the industry from last 20 years.</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-2 grid-sm-6 grid-xs-6">
                        <div className="footer-col">
                            <h3>About Us</h3>
                            <ul className="footer-col-menu">
                                <li><Link to="#">About SavNƏT</Link></li>
                                <li><Link to="/about-us#director-message">Director Message</Link></li>
                                <li><Link to="/about-us#why-us">Why SavNƏT Coaching</Link></li>
                                <li><Link to="/courses">All Courses</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="grid-2 grid-sm-6 grid-xs-6">
                    <div className="footer-col">
                            <h3>Quick Courses Link</h3>
                            <ul className="footer-col-menu">
                                <li><Link to="/registration?software-development">Software Development</Link></li>
                                <li><Link to="/registration?system">System</Link></li>
                                <li><Link to="/registration?networks">Networks Us</Link></li>
                                <li><Link to="/registration?cloud">Cloud</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="grid-3 grid-sm-12 grid-xs-12">
                        <div className="footer-col">
                            <h3>Get In Touch</h3>
                            <div className="content">
                                {/* <p><b>Phone: </b> +91 90000909900 </p> */}
                                <p><b>Email: </b> savnetedu@gmail.com </p>
                            </div>
                            <br/>
                            <h3>Connect on Social</h3>
                            <ul className="social">
                                <li><a href="https://www.facebook.com/savnetit" target="_blank"><ion-icon name="logo-facebook"></ion-icon></a></li>
                                {/* <li><Link to="#"><ion-icon name="logo-twitter"></ion-icon></Link></li>
                                <li><Link to="#"><ion-icon name="logo-instagram"></ion-icon></Link></li>
                                <li><Link to="#"><ion-icon name="logo-youtube"></ion-icon></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterCreds/>
        </>
    )
}

export default Footer