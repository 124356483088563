
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import AOS from 'aos'
import 'aos/dist/aos.css'

import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import Footer from "../components/footer"

import "../assets/styles/normalize.css"
import "../assets/styles/grid.scss"
import "../assets/styles/main.scss"

const Layout = ({ children }) => {

    useEffect(() => {
        document.body.classList.remove('menuopen');
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, [])

    return (
        <div className="container">
            <Header />
            {children}
            <Footer />
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout